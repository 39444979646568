
import { AttendanceSettingsData, AttendanceViewMode, defaultAttendanceViewMode } from '@/Model/attendance';
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component({})
export default class AttendanceSettings extends Vue {
  @Prop({ type: String as PropType<AttendanceViewMode>, default: defaultAttendanceViewMode })
  readonly viewMode!: AttendanceViewMode;

  private dailyViewMode: AttendanceViewMode = 'daily';
  private weeklyViewMode: AttendanceViewMode = 'weekly';
  private internalViewMode: AttendanceViewMode = defaultAttendanceViewMode;

  @Watch('viewMode', { immediate: true })
  viewModeWatcher(newValue: AttendanceViewMode): void {
    this.internalViewMode = newValue;
  }

  cancel(): void {
    this.internalViewMode = this.viewMode;
    this.$emit('cancel');
  }

  save(): void {
    this.$emit('save', {
      viewMode: this.internalViewMode,
    } as AttendanceSettingsData);
  }
}
